import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleAssignmentService {
  private apiUrl = environment.API_BASE;
  private httpOptions;

  constructor(private http: HttpClient) {
    this.httpOptions = this.createAuthorizationHeader();
  }

  private createAuthorizationHeader() {
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
    };
    let headers = new HttpHeaders(headerLogin);
    return { headers: headers };
  }

  /**
   * Get all active role assignments
   */
  getRoleAssignments(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/role-assignments`, this.httpOptions);
  }

  /**
   * Get a specific role assignment by ID
   */
  getRoleAssignment(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/role-assignments/${id}`, this.httpOptions);
  }

  /**
   * Create a new role assignment
   */
  createRoleAssignment(assignment: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/api/role-assignments`, assignment, this.httpOptions);
  }

  /**
   * Update an existing role assignment
   */
  updateRoleAssignment(id: number, assignment: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/api/role-assignments/${id}`, assignment, this.httpOptions);
  }

  /**
   * Delete a role assignment (soft delete by setting active=0)
   */
  deleteRoleAssignment(id: number): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/api/role-assignments/${id}`, this.httpOptions);
  }

  /**
   * Get all active users for dropdown selection
   */
  getActiveUsers(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/active-users`, this.httpOptions);
  }

  /**
   * Get all open roles for dropdown selection
   */
  getOpenRoles(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/open-roles`, this.httpOptions);
  }
}
