import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'workflow-details-dialog',
  template: `
    <h2 mat-dialog-title>{{data.status}} Workflows for {{data.recruiter}}</h2>
    <div mat-dialog-content>
      <div *ngIf="data.workflows.length === 0" class="no-data">
        No workflow details available.
      </div>
      <table class="table table-striped" *ngIf="data.workflows.length > 0">
        <thead>
          <tr>
            <th>Candidate</th>
            <th>Role</th>
            <th>Client</th>
            <th>Notes</th>
            <th>Status</th>
            <th>Last Update</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let workflow of data.workflows">
            <td>{{workflow.candidate_name || workflow.candidate_id}}</td>
            <td>{{workflow.role_title || workflow.role_id}}</td>
            <td>{{workflow.client}}</td>
            <td>{{workflow.notes}}</td>
            <td>{{workflow.latest_status}}</td>
            <td>{{workflow.last_update | date:'medium'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Close</button>
    </div>
  `,
  styles: [`
    .no-data {
      padding: 20px;
      text-align: center;
      font-style: italic;
    }
    table {
      width: 100%;
    }
  `]
})
export class WorkflowDetailsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<WorkflowDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
