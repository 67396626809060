import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientGroupService {
  private apiUrl = environment.API_BASE.endsWith('/') 
    ? environment.API_BASE.slice(0, -1) 
    : environment.API_BASE;
  
  // API endpoints for client groups and client-group associations
  
  createAuthorizationHeader() {
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
    };
    let headers = new HttpHeaders(headerLogin);
    return { headers: headers };
  }

  httpOptions = this.createAuthorizationHeader();
  
  constructor(private http: HttpClient) { }

  // Client Group endpoints
  getClientGroups(): Observable<any> {
    console.log('API URL:', this.apiUrl);
    return this.http.get(`${this.apiUrl}/client_groups`, this.httpOptions)
      .pipe(
        catchError(error => {
          console.error('Error in getClientGroups:', error);
          return throwError(error);
        })
      );
  }

  getClientGroup(id: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/client_group/${id}`, this.httpOptions)
      .pipe(
        catchError(error => {
          console.error('Error in getClientGroup:', error);
          return throwError(error);
        })
      );
  }

  createClientGroup(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/client_group`, data, this.httpOptions)
      .pipe(
        catchError(error => {
          console.error('Error in createClientGroup:', error);
          return throwError(error);
        })
      );
  }

  updateClientGroup(id: number, data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/client_group/${id}`, data, this.httpOptions)
      .pipe(
        catchError(error => {
          console.error('Error in updateClientGroup:', error);
          return throwError(error);
        })
      );
  }

  deleteClientGroup(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/client_group/${id}`, this.httpOptions)
      .pipe(
        catchError(error => {
          console.error('Error in deleteClientGroup:', error);
          return throwError(error);
        })
      );
  }

  // Client and Group endpoints
  getClientsAndGroups(): Observable<any> {
    return this.http.get(`${this.apiUrl}/clients_and_groups`, this.httpOptions)
      .pipe(
        catchError(error => {
          console.error('Error in getClientsAndGroups:', error);
          return throwError(error);
        })
      );
  }

  getClientAndGroup(id: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/client_and_group/${id}`, this.httpOptions)
      .pipe(
        catchError(error => {
          console.error('Error in getClientAndGroup:', error);
          return throwError(error);
        })
      );
  }

  createClientAndGroup(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/client_and_group`, data, this.httpOptions)
      .pipe(
        catchError(error => {
          console.error('Error in createClientAndGroup:', error);
          return throwError(error);
        })
      );
  }

  updateClientAndGroup(id: number, data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/client_and_group/${id}`, data, this.httpOptions)
      .pipe(
        catchError(error => {
          console.error('Error in updateClientAndGroup:', error);
          return throwError(error);
        })
      );
  }

  deleteClientAndGroup(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/client_and_group/${id}`, this.httpOptions)
      .pipe(
        catchError(error => {
          console.error('Error in deleteClientAndGroup:', error);
          return throwError(error);
        })
      );
  }
}
