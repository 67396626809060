import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RoleAssignmentService } from '../role-assignment.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { TalentService } from '../talent.service';

@Component({
  selector: 'app-role-assignment',
  templateUrl: './role-assignment.component.html',
  styleUrls: ['./role-assignment.component.css']
})
export class RoleAssignmentComponent implements OnInit {
  displayedColumns: string[] = ['id', 'role_title', 'client_name', 'recruiter_name', 'acct_mgr_name', 'actions'];
  dataSource: MatTableDataSource<any>;
  isLoading = true;
  errorMessage = '';
  
  activeUsers: any[] = [];
  openRoles: any[] = [];
  
  newAssignment = {
    recruiter: null,
    acct_mgr: null,
    role_id: null
  };
  
  editMode = false;
  currentAssignmentId: number | null = null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  isAdmin = false;
  userId: number;

  constructor(
    private roleAssignmentService: RoleAssignmentService,
    private dialog: MatDialog,
    private talentService: TalentService
  ) {
    this.userId = parseInt(localStorage.getItem('user_id') || '0', 10);
    this.isAdmin = this.userId === 1 || this.userId === 1020;
  }

  ngOnInit(): void {
    this.loadRoleAssignments();
    this.loadActiveUsers();
    this.loadOpenRoles();
  }

  loadRoleAssignments(): void {
    this.isLoading = true;
    this.roleAssignmentService.getRoleAssignments().subscribe(
      (data) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error loading role assignments:', error);
        this.errorMessage = 'Failed to load role assignments. Please try again later.';
        this.isLoading = false;
      }
    );
  }

  loadActiveUsers(): void {
    this.roleAssignmentService.getActiveUsers().subscribe(
      (data) => {
        this.activeUsers = data;
      },
      (error) => {
        console.error('Error loading active users:', error);
      }
    );
  }

  loadOpenRoles(): void {
    this.roleAssignmentService.getOpenRoles().subscribe(
      (data) => {
        this.openRoles = data;
      },
      (error) => {
        console.error('Error loading open roles:', error);
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  createAssignment(): void {
    if (!this.newAssignment.recruiter || !this.newAssignment.acct_mgr || !this.newAssignment.role_id) {
      this.errorMessage = 'Please fill in all required fields.';
      return;
    }

    this.isLoading = true;
    this.errorMessage = '';

    if (this.editMode && this.currentAssignmentId) {
      // Update existing assignment
      this.roleAssignmentService.updateRoleAssignment(this.currentAssignmentId, this.newAssignment).subscribe(
        (response) => {
          this.loadRoleAssignments();
          this.resetForm();
          this.isLoading = false;
        },
        (error) => {
          console.error('Error updating role assignment:', error);
          this.errorMessage = 'Failed to update role assignment. Please try again.';
          this.isLoading = false;
        }
      );
    } else {
      // Create new assignment
      this.roleAssignmentService.createRoleAssignment(this.newAssignment).subscribe(
        (response) => {
          this.loadRoleAssignments();
          this.resetForm();
          this.isLoading = false;
        },
        (error) => {
          console.error('Error creating role assignment:', error);
          this.errorMessage = 'Failed to create role assignment. Please try again.';
          this.isLoading = false;
        }
      );
    }
  }

  editAssignment(assignment: any): void {
    this.editMode = true;
    this.currentAssignmentId = assignment.id;
    this.newAssignment = {
      recruiter: assignment.recruiter,
      acct_mgr: assignment.acct_mgr,
      role_id: assignment.role_id
    };
  }

  deleteAssignment(id: number): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: { 
        title: 'Confirm Delete', 
        message: 'Are you sure you want to delete this role assignment?' 
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = true;
        this.roleAssignmentService.deleteRoleAssignment(id).subscribe(
          () => {
            this.loadRoleAssignments();
            this.isLoading = false;
          },
          (error) => {
            console.error('Error deleting role assignment:', error);
            this.errorMessage = 'Failed to delete role assignment. Please try again.';
            this.isLoading = false;
          }
        );
      }
    });
  }

  resetForm(): void {
    this.newAssignment = {
      recruiter: null,
      acct_mgr: null,
      role_id: null
    };
    this.editMode = false;
    this.currentAssignmentId = null;
    this.errorMessage = '';
  }

  compareById(item1: any, item2: any): boolean {
    return item1 && item2 && item1 === item2;
  }
}
