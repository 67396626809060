import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ClientGroupService } from '../client-group.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-client-group-management',
  templateUrl: './client-group-management.component.html',
  styleUrls: ['./client-group-management.component.css']
})
export class ClientGroupManagementComponent implements OnInit {
  // Client Group properties
  clientGroupForm: FormGroup;
  clientGroups: any[] = [];
  selectedClientGroup: any = null;
  clientGroupDisplayedColumns: string[] = ['rowNum', 'id', 'name', 'ranking', 'actions'];
  clientGroupDataSource = new MatTableDataSource<any>();

  // Client and Group properties
  clientAndGroupForm: FormGroup;
  clientsAndGroups: any[] = [];
  selectedClientAndGroup: any = null;
  clientAndGroupDisplayedColumns: string[] = ['rowNum', 'id', 'name', 'short_name', 'client_group', 'actions'];
  clientAndGroupDataSource = new MatTableDataSource<any>();

  @ViewChild('clientGroupPaginator', { static: true }) clientGroupPaginator: MatPaginator;
  @ViewChild('clientGroupSort', { static: true }) clientGroupSort: MatSort;
  @ViewChild('clientAndGroupPaginator', { static: true }) clientAndGroupPaginator: MatPaginator;
  @ViewChild('clientAndGroupSort', { static: true }) clientAndGroupSort: MatSort;

  constructor(
    private fb: FormBuilder,
    private clientGroupService: ClientGroupService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.initForms();
    this.loadClientGroups();
    this.loadClientsAndGroups();
  }

  initForms(): void {
    this.clientGroupForm = this.fb.group({
      name: ['', Validators.required],
      ranking: [0, Validators.required]
    });

    this.clientAndGroupForm = this.fb.group({
      name: ['', Validators.required],
      short_name: [''],
      client_group: ['', Validators.required]
    });
  }

  // Client Group methods
  loadClientGroups(): void {
    this.clientGroupService.getClientGroups().subscribe(
      (data) => {
        console.log('Client groups data:', data);
        this.clientGroups = data;
        this.clientGroupDataSource.data = this.clientGroups;
        this.clientGroupDataSource.paginator = this.clientGroupPaginator;
        this.clientGroupDataSource.sort = this.clientGroupSort;
      },
      (error) => {
        console.error('Error loading client groups:', error);
        alert('Error loading client groups: ' + (error.message || JSON.stringify(error)));
      }
    );
  }

  saveClientGroup(): void {
    if (this.clientGroupForm.invalid) {
      return;
    }

    const clientGroupData = this.clientGroupForm.value;

    if (this.selectedClientGroup) {
      // Update existing client group
      this.clientGroupService.updateClientGroup(this.selectedClientGroup.id, clientGroupData).subscribe(
        () => {
          this.loadClientGroups();
          this.resetClientGroupForm();
          alert('Client group updated successfully!');
        },
        (error) => {
          console.error('Error updating client group:', error);
          alert('Error updating client group: ' + (error.message || 'Unknown error'));
        }
      );
    } else {
      // Create new client group
      this.clientGroupService.createClientGroup(clientGroupData).subscribe(
        () => {
          this.loadClientGroups();
          this.resetClientGroupForm();
          alert('Client group created successfully!');
        },
        (error) => {
          console.error('Error creating client group:', error);
          alert('Error creating client group: ' + (error.message || 'Unknown error'));
        }
      );
    }
  }

  editClientGroup(clientGroup: any): void {
    this.selectedClientGroup = clientGroup;
    this.clientGroupForm.patchValue({
      name: clientGroup.name,
      ranking: clientGroup.ranking
    });
  }

  deleteClientGroup(clientGroup: any): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: { title: 'Confirm Delete', message: `Are you sure you want to delete the client group "${clientGroup.name}"?` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientGroupService.deleteClientGroup(clientGroup.id).subscribe(
          () => {
            this.loadClientGroups();
          },
          (error) => {
            console.error('Error deleting client group:', error);
          }
        );
      }
    });
  }

  resetClientGroupForm(): void {
    this.selectedClientGroup = null;
    this.clientGroupForm.reset({
      ranking: 0
    });
    this.clientGroupForm.markAsUntouched();
    this.clientGroupForm.markAsPristine();
  }

  // Client and Group methods
  loadClientsAndGroups(): void {
    this.clientGroupService.getClientsAndGroups().subscribe(
      (data) => {
        console.log('Clients and groups data:', data);
        this.clientsAndGroups = data;
        this.clientAndGroupDataSource.data = this.clientsAndGroups;
        this.clientAndGroupDataSource.paginator = this.clientAndGroupPaginator;
        this.clientAndGroupDataSource.sort = this.clientAndGroupSort;
      },
      (error) => {
        console.error('Error loading clients and groups:', error);
        alert('Error loading clients and groups: ' + (error.message || JSON.stringify(error)));
      }
    );
  }

  saveClientAndGroup(): void {
    if (this.clientAndGroupForm.invalid) {
      return;
    }

    const clientAndGroupData = this.clientAndGroupForm.value;

    if (this.selectedClientAndGroup) {
      // Update existing client and group
      this.clientGroupService.updateClientAndGroup(this.selectedClientAndGroup.id, clientAndGroupData).subscribe(
        () => {
          this.loadClientsAndGroups();
          this.resetClientAndGroupForm();
          alert('Client updated successfully!');
        },
        (error) => {
          console.error('Error updating client and group:', error);
          alert('Error updating client: ' + (error.message || 'Unknown error'));
        }
      );
    } else {
      // Create new client and group
      this.clientGroupService.createClientAndGroup(clientAndGroupData).subscribe(
        () => {
          this.loadClientsAndGroups();
          this.resetClientAndGroupForm();
          alert('Client created successfully!');
        },
        (error) => {
          console.error('Error creating client and group:', error);
          alert('Error creating client: ' + (error.message || 'Unknown error'));
        }
      );
    }
  }

  editClientAndGroup(clientAndGroup: any): void {
    this.selectedClientAndGroup = clientAndGroup;
    this.clientAndGroupForm.patchValue({
      name: clientAndGroup.name,
      short_name: clientAndGroup.short_name,
      client_group: clientAndGroup.client_group
    });
  }

  deleteClientAndGroup(clientAndGroup: any): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: { title: 'Confirm Delete', message: `Are you sure you want to delete the client "${clientAndGroup.name}"?` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientGroupService.deleteClientAndGroup(clientAndGroup.id).subscribe(
          () => {
            this.loadClientsAndGroups();
          },
          (error) => {
            console.error('Error deleting client and group:', error);
          }
        );
      }
    });
  }

  resetClientAndGroupForm(): void {
    this.selectedClientAndGroup = null;
    this.clientAndGroupForm.reset({
      name: '',
      short_name: '',
      client_group: ''
    });
    this.clientAndGroupForm.markAsUntouched();
    this.clientAndGroupForm.markAsPristine();
  }

  // Helper methods
  getClientGroupName(id: number): string {
    const group = this.clientGroups.find(g => g.id === id);
    return group ? group.name : '';
  }

  applyClientGroupFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.clientGroupDataSource.filter = filterValue.trim().toLowerCase();

    if (this.clientGroupDataSource.paginator) {
      this.clientGroupDataSource.paginator.firstPage();
    }
  }

  applyClientAndGroupFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    
    // Custom filter predicate that includes client group name
    this.clientAndGroupDataSource.filterPredicate = (data: any, filter: string) => {
      // Get the client group name
      const clientGroupName = this.getClientGroupName(data.client_group).toLowerCase();
      
      // Check if the filter matches client name, short name, or client group name
      return data.name.toLowerCase().includes(filter) || 
             (data.short_name && data.short_name.toLowerCase().includes(filter)) ||
             clientGroupName.includes(filter);
    };
    
    this.clientAndGroupDataSource.filter = filterValue;

    if (this.clientAndGroupDataSource.paginator) {
      this.clientAndGroupDataSource.paginator.firstPage();
    }
  }
}
